@tailwind base;
@tailwind components;
@tailwind utilities;

/* Custom utility for the red triangle in the top-right corner */
.triangle-bottom-right {
  @apply absolute bottom-0 right-0 w-0 h-0 border-solid;
  border-width: 0 0 60px 60px ;
  border-color: transparent transparent #e53935 transparent;
  border-radius: 0 0 8px 0;
}
